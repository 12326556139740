













































































































































































































































































































import { useAuth } from '@/modules/auth';
import { defineComponent, computed } from '@vue/composition-api';
import {
  LOGOUT_PATH,
  PROJECT_START_PATH,
  PROJECT_CALCULATION_PATH,
  PROJECT_OVERVIEW_PATH,
  PROJECT_CALCULATION_OVERVIEW_PATH,
  HOME_PATH,
  PROJECT_CALCULATION_BUILDING_ELEMENT_PATH,
  VERSIONS_PATH,
  PROJECT_CALCULATION_ENVIRONMENT,
  PROJECT_CALCULATION_PRICE_UPDATE_PATH,
} from '@/constants/routes';
import UpgradeFromTrial from '@/layout/components/upgrade-license/index.vue';
import UserPwModal from '@/components/user-adm/user-pwreset-modal/index.vue';
import Language from '@/layout/components/language/index.vue';
import { useProject } from '@/modules/project';
import { useCalculation } from '@/modules/calculation';
import { useGlobal } from '@/modules/global';
import router from '@/router';
import { isStorageSupported } from '@/helpers/common';
import { USER_READ_PATCH_NOTES } from '@/constants/common';

export default defineComponent({
  components: {
    UpgradeFromTrial,
    UserPwModal,
    Language,
  },
  setup() {
    const { user, license } = useAuth();
    const { activeProjectOverview } = useProject();
    const { projectCalculation } = useCalculation();
    const {
      isOnRoute,
      requestFeedback,
      isIsolatingElement,
      setUserReadPatchNotes,
      appVersion,
      userReadPatchNotes,
    } = useGlobal();

    const routeToVersions = async () => {
      await router.push(VERSIONS_PATH);
    };

    const showWhatsNew = computed(() => {
      let show = false;

      show = userReadPatchNotes.value;

      if (isStorageSupported()) {
        const hasRead = localStorage.getItem(USER_READ_PATCH_NOTES);
        if (hasRead) {
          show = hasRead === 'false';
        }
      }

      return show;
    });

    return {
      isOnRoute,
      requestFeedback,
      isIsolatingElement,
      setUserReadPatchNotes,
      routeToVersions,
      appVersion,
      activeProjectOverview,
      LOGOUT_PATH,
      PROJECT_START_PATH,
      PROJECT_CALCULATION_PATH,
      PROJECT_OVERVIEW_PATH,
      PROJECT_CALCULATION_OVERVIEW_PATH,
      PROJECT_CALCULATION_BUILDING_ELEMENT_PATH,
      PROJECT_CALCULATION_ENVIRONMENT,
      PROJECT_CALCULATION_PRICE_UPDATE_PATH,
      HOME_PATH,
      VERSIONS_PATH,
      showWhatsNew,
      user,
      license,
      projectCalculation,
      supportUrl: process.env.VUE_APP_SUPPORT_URL,
      documentationUrl: process.env.VUE_APP_DOCUMENTATION_URL,
    };
  },
});
